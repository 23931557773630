import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="banner">
        <h3 className="text-secondary">Website under construction</h3>
        <h1 className="text-light display-1 p-4">Coming soon...</h1>
        <h3 className="text-secondary">Stay tuned</h3>
      </div>
    </div>
  );
}

export default App;
